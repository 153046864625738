<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"></i18n>
<i18n src="@i18n/customer/customer.th.yaml" locale="th" lang="yaml"></i18n>
<i18n locale="th" lang="yaml" >
page.title: "Import Drone เพื่อลงทะเบียน"
page.header : "Import Drone เพื่อลงทะเบียน"
page.description : "ทำการ Import Drone ตามข้อมูล Excel เพื่อทำการลงทะเบียน การใช้งาน Drone"

import.data.error.title : "ข้อมูลผิดพลาด"
import.data.error : "ข้อมูลที่นำเข้าระบบมีข้อผิดพลาด กรุณาตรวจสอบ"
import.file.error.title : "ไฟล์ผิดพลาด"
import.confirm.title : "ยืนยันการ Import"
import.confirm.content : "คุณยืนยันที่จะนำข้อมูล Drone ลงทะเบียน เข้าระบบ ? (ไม่สามารถแก้ไขภายหลังได้)"
import.confirm.success.title : "Import เรียบร้อย"
import.confirm.success.content : "ทำการนำข้อมูล Drone ลงทะเบียน จำนวน {num} @:common.drone.unit เข้าระบบเป็นที่เรียบร้อย"
import.confirm.error.title : "นำเข้าข้อมูลผิดพลาด"

upload.label : "Upload Excel"
error_only.label : "แสดงที่ผิดพลาด"
warning_only.label : "แสดงที่เตือน"
confirm.label : "นำข้อมูลเข้าระบบ"

button.export_template : "Download ตัวอย่าง Excel"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.header')"
			:description="$t('page.description')"/>

		<a-alert v-if="hasErrors" type="error"
			style="margin-bottom:8px;"
			:message="errorMessageTitle"
			:description="errorMessage"
			show-icon/>

		<div class="myform-action-pane">
			<a-upload
				:disabled="uploading"
				accept=".xlsx"
				:show-upload-list ="false"
				:before-upload="uploadFile">
				<a-button :loading="uploading"><a-icon type="upload" />{{$t('upload.label')}}</a-button>
			</a-upload>

			<a-button
				icon="download"
				@click="clickExportTemplate">
				{{$t('button.export_template')}}
			</a-button>
		</div>

		<a-card v-if="importSuccess" :bordered="false" :loading="uploading">
			<div style="margin-bottom:8px;">
				<a-checkbox v-model="filter.errorOnly">
					{{$t('error_only.label')}}
				</a-checkbox>
			</div>
			<a-button :disabled="!canConfirmImport" class="btn-page-action"
				type="primary"
				@click="handleConfirmImport">
				{{$t("confirm.label")}}
			</a-button>

			<a-table
				bordered
				class="mytable no-hover table-import"
				:row-key="record => record.excel_row"
				:data-source="displayImportDrones"
				:pagination="{ pageSize: 25 ,currentPage: currentPage }"
				:columns="tableColumns"
				size="small">
				<span slot="dataCell" slot-scope="text,record,index,column">
					{{text}}
					<ImportNoticeIcon :model="record" :name="column.key" />
				</span>
				<span slot="dateCell" slot-scope="text,record,index,column">
					{{text | date}}
					<ImportNoticeIcon :model="record" :name="column.key" />
				</span>
				<span slot="customerTypeCell" slot-scope="text">
					{{$tenum('customer_type',text)}}
				</span>
				<span slot="flightTime" slot-scope="text,record">
					<FlightTimeView
							:time-seconds="text"/>
					<ImportNoticeIcon :model="record" name="drone_flight_time_sec" />
				</span>
				<span slot="addressCell" slot-scope="text,record">
					{{getCustomerAddressDisplay(record)}}
				</span>

			</a-table>
		</a-card>
	</div>
</template>

<script>
import ImportNoticeIcon from "@components/common/ImportNoticeIcon.vue"
import PageMixin from "@mixins/PageMixin.vue"
import {Upload,Table,Checkbox} from "ant-design-vue"
import axios from "axios"
import {isVarEmpty,addressDisplay} from "@utils/stringUtil"
import fileDownload from "js-file-download"
import ApiError from '@utils/errors/ApiError'
import { mapActions } from 'vuex'
import FlightTimeView from "@components/common/FlightTimeView.vue"


export default {
	page() {
		return {title : this.$t('page.title')}
	} ,
	components : {
		"a-upload" : Upload , "a-table" : Table,
		"a-checkbox" : Checkbox,
		ImportNoticeIcon,FlightTimeView
	},
	mixins : [PageMixin] ,
	data() {
		return {
			uploading : false,
			hasErrors : false,
			importSuccess : false,
			importDrones : [] ,
			errorMessageTitle : null,
			errorMessage : null,
			currentPage : 1,
			filter : {
				errorOnly : false,
			}
		}
	} ,
	computed : {
		displayImportDrones() {
			return this.importDrones.filter((drone) => {
				let show = true;
				if (this.filter.errorOnly) {
					show = !isVarEmpty(drone.errors);
				}
				return show;
			})
		} ,
		canConfirmImport() {
			if (!this.importSuccess || this.hasErrors)
				return false;
			return this.importDrones.length > 0;
		} ,
		tableColumns() {
			return [
				{
					dataIndex : 'excel_row' ,
					title : this.$t('common.table.column.excel_row'),
					align : 'center',
					width : 50,
					scopedSlots : {customRender : 'dataCell'}
				} ,
				{
					dataIndex : 'drone_name' ,
					title : this.$t('drone.field.name') ,
					width : 150,
					scopedSlots : {customRender : 'dataCell'}
				} ,
				{
					dataIndex : 'drone_serial_no' ,
					title : this.$t('drone.field.serial_no') ,
					width : 135,
					scopedSlots : {customRender : 'dataCell'}
				} ,
				{
					dataIndex : 'drone_warranty_start_date' ,
					title : this.$t('drone.field.warranty_start_date') ,
					width : 135,
					scopedSlots : {customRender : 'dateCell'}
				} ,
				{
					dataIndex : 'firmware_name' ,
					title : this.$t('drone.field.firmware') ,
					width : 100,
					scopedSlots : {customRender : 'dataCell'}
				} ,
				{
					dataIndex : 'drone_flight_time_sec' ,
					title : this.$t('drone.field.latest_flight_time_sec'),
					width : 135,
					scopedSlots : {customRender : 'flightTime'}
				} ,
				{
					dataIndex : 'name' ,
					title : this.$t('customer.field.name'),
					scopedSlots : {customRender : 'dataCell'}
				} ,
				{
					dataIndex : 'customer_type' ,
					title : this.$t('customer.field.customer_type'),
					align : "center" ,
					scopedSlots : {customRender : 'customerTypeCell'}
				} ,
				{
					dataIndex : 'identity_no' ,
					title : this.$t('customer.field.identity_no'),
					scopedSlots : {customRender : 'dataCell'}
				} ,
				{
					dataIndex : 'address' ,
					title : this.$t('customer.field.address'),
					scopedSlots : {customRender : 'addressCell'}
				} ,
				{
					dataIndex : 'phone' ,
					title : this.$t('customer.field.phone'),
					scopedSlots : {customRender : 'dataCell'}
				} ,
				{
					dataIndex : 'email' ,
					title : this.$t('customer.field.email'),
					scopedSlots : {customRender : 'dataCell'}
				} ,
			]
		}
	} ,
	methods :{
		...mapActions('app',['collapsedSideMenu']) ,
		getCustomerAddressDisplay(drone) {
			return addressDisplay(this,drone.address,drone);
		} ,
		clickExportTemplate() {
			this.showPageLoading(true)
			axios.get("/api/drones/export-registered-template",{
				responseType: 'blob'
			}).then((response) => {
				fileDownload(response.data,"drones_register.xlsx");
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error));
			}).finally(() => {
				this.hidePageLoading()
			})
		} ,
		uploadFile(file) {
			this.uploading = true;
			const formData = new FormData();
			formData.append('file',file);

			axios.post('/api/drones/import-registered',formData).then((response)=>{
				this.importDrones = response.data.data.import_data;
				this.hasErrors = response.data.data.has_errors;
				this.importSuccess = true;
				this.filter.errorOnly = false;
				this.currentPage = 1;
				if (this.hasErrors) {
					this.errorMessageTitle = this.$t('import.data.error.title');
					this.errorMessage = this.$t('import.data.error')
				}
				this.collapsedSideMenu(true);
			}).catch((error)=>{
				this.hasErrors = true;
				this.errorMessageTitle = this.$t('import.file.error.title');
				this.errorMessage = ApiError.getDisplayErrorMessage(this,error);
			}).finally(()=>{
				this.uploading = false
			})
			return false;
		} ,
		handleConfirmImport() {
			if (this.hasErrors)
				return;
			this.$confirm({
				title : this.$t('import.confirm.title') ,
				content : this.$t('import.confirm.content') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk : () => {
					this.showPageLoading()
					const formData = this.importDrones;
					axios.post("/api/drones/import-registered-confirm",formData).then((response)=>{
						const successTitle = this.$t('import.confirm.success.title');
						const successContent = this.$t('import.confirm.success.content',{num : response.data.data.num_drones});

						this.$notification.success({
							message :  successTitle,
							description : successContent,
						});
						this.$router.push({name: 'admin/drone_list'})
					}).catch((error) => {
						this.hasErrors = true;
						this.errorMessageTitle = this.$t('import.confirm.error.title');
						this.errorMessage = ApiError.getDisplayErrorMessage(this,error)
					}).finally(()=>{
						this.hidePageLoading();
					})
				} ,
				onCancel() {}
			});
		}
	}
}
</script>
